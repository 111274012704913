<script>
import { Bar } from "vue-chartjs";
import { apiRequest } from "@/helpers/api-call";

export default {
  props: {
    annee: {
      type: Number,
      default: null,
    },
  },
  extends: Bar,
  data() {
    return {
      dataGain: null,
      dataNombre: [],
      mois: [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Jui",
        "Juil",
        "Août",
        "Sept",
        "Oct",
        "Nov",
        "Déc",
      ],
      dataMois: [],
    };
  },
  watch: {
    annee: {
      handler() {
       
        this.loadData();
      },
    },
  },
  async mounted() {
    //console.log("this.annee:", this.annee);
    this.loadData();
  },
  methods: {
    async loadData() {
      this.dataNombre = [];
      this.dataMois = [];

      const stat = await apiRequest(
        "GET",
        "admin/stat-user",
        undefined,
        false
      );

      if (stat && stat.data) {
        var dataStat = stat.data;
        this.dataNombre=[]
        this.dataMois=[]
        dataStat.all.forEach((element) => {
          if (element.annee == this.annee) {
            this.dataNombre.push(element.nombre);
            this.dataMois.push(this.mois[element.mois - 1]);
          }
        });

        this.renderChart(
          {
            labels: this.dataMois,
            datasets: [
              {
                label: "Utilisateur",
                barPercentage: 0.4,
                backgroundColor: "#3AAA35 ",
                borderColor: "#3AAA35 ",
                borderWidth: 2,
                hoverBackgroundColor: "#3AAA35 ",
                hoverBorderColor: "#3AAA35 ",
                data: this.dataNombre,
              },
            ],
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
            },
          }
        );
      }
    },
  },
};
</script>
<style>


</style>
