<script>
import { Bar } from "vue-chartjs";
import { apiRequest } from "@/helpers/api-call";

export default {
  props: {
    annee: {
      type: Number,
      default: null,
    },
  },
  extends: Bar,
  data() {
    return {
      dataGain: null,
      dataRecette: [],
      mois: [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Jui",
        "Juil",
        "Août",
        "Sept",
        "Oct",
        "Nov",
        "Déc",
      ],
      dataMois: [],
    };
  },
  watch: {
    annee: {
      handler() {
       
        this.loadData();
      },
    },
  },
  async mounted() {
    //console.log("this.annee:", this.annee);
    this.loadData();
  },
  methods: {

    async loadData() {
      console.log("location anee", this.annee)
      this.dataRecette = [];
      this.dataMois = [];

      const gains = await apiRequest(
        "GET",
        `admin/gain/info/location?annee=${this.annee}`
      );

      console.log("gains",gains)

      if (gains && gains.data) {
        var dataGain = gains.data;
        this.dataRecette=[]
        this.dataMois=[]
        dataGain.stat_months.forEach((element) => {
          if (element.annee == this.annee) {
            this.dataRecette.push(element.chiffreAffaire);
            this.dataMois.push(this.mois[element.mois -1 ]);
           
          }
        });



        this.renderChart(
          {
            labels: this.dataMois,
            datasets: [
              {
                label: "CA Location",
                barPercentage: 0.4,
                backgroundColor: "#3AAA35 ",
                borderColor: "#3AAA35 ",
                borderWidth: 2,
                hoverBackgroundColor: "#3AAA35 ",
                hoverBorderColor: "#3AAA35 ",
                data: this.dataRecette, //[65, 59, 81, 45, 56, 80, 50, 20]
              },
            ],
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
            },
          }
        );
      
        this.$emit("totalCALocation",gains.data.total_ca_annee);

      }
    },
  },
};
</script>
