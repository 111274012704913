<script>
//import div from "div-vue";

import { formatDistance } from "date-fns";
//import { fr } from 'date-fns/locale'
import db from "@/firebase/init.js";
import Multiselect from "vue-multiselect";
import router from "@/router/index.js";


// Required for side-effects
require("firebase/firestore");

export default {
  //components: { div },

  components: {
    Multiselect,
  },
  data() {
    return {
      audio: require("@/assets/audio/ringtone.wav"),
      newnotif: [],
      notif: [],
      db: db,
      total: 0,
      allAnniversaryId: [],
      showConfirm: false,

      numberOfNotif: 100,
      rangeNotification: [
        100, 200, 300, 400, 500, 600, 700, 800, 900, 1000
      ]

    };
  },
  async mounted() {
    // Initialize Cloud Firestore through Firebase
    /*var db = firebaseApp.firestore();
    this.getAll(db);
    console.log('this.notif:', this.notif)*/


  },
  created() {
    // Initialize Cloud Firestore through Firebase

    this.db.collection("notifdash").onSnapshot((res) => {
      const changes = res.docChanges();
      console.log("changes:", changes);

      this.getAll();
      console.log(this.notif);
    });
  },
  methods: {

    async playAudio() {
      const audioT = new Audio(this.audio);
      //audioT.muted = true; // Ajouter l'attribut muted
      await audioT.play();
    },

    async handleNotification(data, docId) {
      console.log("data god", data)
      if (data.seen == false) {
        this.total++;
        this.notif.push({
          id: docId,
          idUser: data.idUser,
          textNotif: data.textNotif,
          seen: data.seen,
          typeNotif: data.typeNotif,
          dateNotif: formatDistance(
            new Date(data.dateNotif.toDate()),
            new Date(),
            { addSuffix: true },
            { locale: "fr_FR" }
          ),
        });

        // Vérifier si typeNotif est égal à 2 et jouer la sonnerie
        if (data.typeNotif == 2) {
          await this.playAudio();
        }
      }
    },
    getAll() {
      this.notif = [];
      this.db
        .collection("notifdash")
        .orderBy("dateNotif", "desc")
        .limit(this.numberOfNotif)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var data = doc.data();
            this.handleNotification(data, doc.id);
          });
        });
      /* setTimeout(() => { 
          this.totalNotif = this.notif.length; 
          console.log("notif:", this.totalNotif); 
      }, 4000); */
    },
    //     getAll() {
    //   this.notif = [];
    //   this.db
    //     .collection("notifdash")
    //     .orderBy("dateNotif", "desc")
    //     .limit(this.numberOfNotif)
    //     .get()
    //     .then((querySnapshot) => {
    //        querySnapshot.forEach((doc) => {
    //         var data = doc.data();
    //         if (data.seen == false) {
    //           this.total++;
    //           this.notif.push({
    //             id: doc.id,
    //             idUser: data.idUser,
    //             textNotif: data.textNotif,
    //             seen: data.seen,
    //             typeNotif: data.typeNotif,
    //             dateNotif: formatDistance(
    //               new Date(data.dateNotif.toDate()),
    //               new Date(),
    //               { addSuffix: true },
    //               { locale: "fr_FR" }
    //             ),
    //           });

    //           // Vérifier si typeNotif est égal à 2 et jouer la sonnerie

    //           if (data.typeNotif == 2 || data.typeNotif == 8) {
    //             this.playAudio();
    //           }
    //         }
    //       });
    //     });
    //   /* setTimeout(() => {
    //     this.totalNotif = this.notif.length;
    //     console.log("notif:", this.totalNotif);
    //   }, 4000); */
    // },

    goToUser(id){
      router.push(`user/user_info/${id}`)
    },
    goToDetail() {
      router.push(`/trajets/effectues`);
    },
    getHistory() {
      this.notif = [];
      this.db
        .collection("notifdash")
        .orderBy("dateNotif", "desc")
        .limit(20)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var data = doc.data();
            console.log("données récupérées", data)
            if (data.seen == true) {
              this.notif.push({
                id: doc.id,
                idUser: data.idUser,
                textNotif: data.textNotif,
                seen: data.seen,
                typeNotif: data.typeNotif,
                dateNotif: formatDistance(
                  new Date(data.dateNotif.toDate()),
                  new Date(),
                  { addSuffix: true },
                  { locale: "fr_FR" }
                ),
              });
            }
          });
        });
    },
    async traiter(id) {
      var aTraiter = this.db.collection("notifdash").doc(id);
      return aTraiter
        .update({
          seen: true,
        })
        .then(() => {
          console.log("Document successfully updated!");

          this.$toast.success("Notification traitée avec succès", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          //this.getAll();
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
      //console.log("id:", id);
    },

    treatAll() {
      this.showConfirm = true;
      this.notif.forEach((element) => {
        console.log("element", element)
        this.allAnniversaryId.push(element.id);
      });
    },

    treatAllNotif() {
      console.log("in here")
      if (this.allAnniversaryId.length > 0) {
        console.log(this.allAnniversaryId);
        this.allAnniversaryId.forEach((element) => {
          console.log(element);
          this.traiter(element);
        });
        this.showConfirm = false;


        this.$toast.success("Notifications traitées avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });

      }
    },
  },

  watch: {
    numberOfNotif: {
      handler(val) {
        console.log("l'année sélectionnée est :", val);
        this.getAll();
      },
    },
  }
};
</script>

<template>
  <div class="card historique">
    <div class="card-body myCorp">
      

      <div class="float-right d-none d-md-inline-block">
        
        <div class="btn-group mb-2">

          <div class="form-group formG w-25 ml-0 mr-5">
        <multiselect :max-height="100" v-model="numberOfNotif" :options="rangeNotification" placeholder="notif">
        </multiselect>
      </div>
         
          <button v-f="allAnniversaryId.length > 0" type="button" class="btn btn-sm lightButton" @click="treatAll">
            <ion-icon name="checkmark-done-outline"></ion-icon>
            <span>Tout traiter</span>
          </button>
          <button type="button" class="btn btn-sm btn-light" @click="getAll">
            Nouvelle
          </button>
          <button type="button" class="btn btn-sm btn-light" @click="getHistory">
            Historique
          </button>
        </div>
      </div>

      
      <!--  <h5 class="card-title mb-3"><span v-if="totalNotif && totalNotif != 0" class="badge">
              {{ totalNotif > 1000 ? "1000+" : totalNotif  v-for="item in notif" :key="item.idUser"}}
            </span></h5> -->
      <div class="flexItem notif-panel" style="max-height: 200px" v-for="item in notif " :key="item.idUser">
        <!-- Pièces ajoutées : type 0 -->
        <div class="text-reset notification-item row rowNotif" v-if="item.typeNotif == 0">
          <span class="col-md-1 mt-4">
            <img src="@/assets/images/user.png" alt height="30" />
          </span>
          <a :href="'/user/user_info/' + item.idUser" class="col-md-10 contenuNotif">
            <div class="media" style="padding: 16px">
              <div class="media-body">
                <h6 class="mt-0 mb-1">Pièces ajoutées</h6>
                <div class="font-size-12 text-muted">
                  <p class="mb-1">
                    {{ item.textNotif }}
                  </p>
                  <p class="mb-0">
                    <i class="mdi mdi-clock-outline"></i>
                    {{ item.dateNotif }}
                  </p>
                </div>
              </div>
            </div>
          </a>
          <a v-if="item.seen == false" href="javascript:void(0);" class="text-succes col-md-1 mt-4" v-b-tooltip.hover
            @click="traiter(item.id)" title="Traiter">
            <i class="mdi mdi-check font-size-18"></i>
          </a>
        </div>

        <!-- Avis : type 1 -->
        <div class="text-reset notification-item row rowNotif mt-3" v-if="item.typeNotif == 1">
          <a :href="'/user/user_info/' + item.idUser" class="col-md-10 contenuNotif">
            <div class="media" style="padding: 16px">
              <div class="media-body">
                <h6 class="mt-0 mb-1">Avis</h6>
                <div class="font-size-12 text-muted">
                  <p class="mb-1">
                    {{ item.textNotif }}
                  </p>
                  <p class="mb-0">
                    <i class="mdi mdi-clock-outline"></i>
                    {{ item.dateNotif }}
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <!-- Urgence : type 2 -->
        <div class="text-reset notification-item row rowNotif mt-3" v-if="item.typeNotif == 2">
          <a :href="'/trajets/urgences'" class="col-md-10 contenuNotif">
            <div class="media " style="padding: 16px">
              <div class="media-body">
                <h6 class="mt-0 mb-1" style="color: red">Urgence</h6>
                <div class="font-size-12 text-muted">
                  <p class="mb-1">
                    {{ item.textNotif }}
                  </p>
                  <p class="mb-0" style="color: red">
                    <i class="mdi mdi-clock-outline" style="color: red"></i>
                    {{ item.dateNotif }}
                  </p>
                </div>
              </div>
            </div>
          </a>
          <a v-if="item.seen == false" href="javascript:void(0);" class="text-succes col-md-1 mt-4" v-b-tooltip.hover
            @click="traiter(item.id)" title="Traiter">
            <i class="mdi mdi-check font-size-18"></i>
          </a>
        </div>
        <!-- Anniversaire : type 3 -->
        <div class="text-reset notification-item row rowNotif mt-3" v-if="item.typeNotif == 3">
          <span class="mt-4 col-md-1">
            <img src="@/assets/images/cake.png" alt height="30" />
          </span>
          <a :href="'/promoannif'" class="col-md-10 contenuNotif">
            <div class="media" style="padding: 16px">
              <div class="media-body">
                <h6 class="mt-0 mb-1">Anniversaire</h6>
                <div class="font-size-12 text-muted">
                  <p class="mb-1">
                    {{ item.textNotif }}
                  </p>
                  <p class="mb-0">
                    <i class="mdi mdi-clock-outline"></i>
                    {{ item.dateNotif }}
                  </p>
                </div>
              </div>
            </div>
          </a>
          <a v-if="item.seen == false" href="javascript:void(0);" class="text-succes col-md-1 mt-4" v-b-tooltip.hover
            @click="traiter(item.id)" title="Traiter">
            <i class="mdi mdi-check font-size-18"></i>
          </a>
        </div>
        <!-- Mise en location : type 4 @/assets/images/car.png -->
        <div class="text-reset notification-item row rowNotif mt-3" v-if="item.typeNotif == 4">
          <span class="mt-4 col-md-1">
            <img src="@/assets/images/car.png" alt height="30" />
          </span>
          <a :href="'/agence/demandes_user'" class="col-md-10 contenuNotif">
            <div class="media" style="padding: 16px">
              <div class="media-body">
                <h6 class="mt-0 mb-1">Location</h6>
                <div class="font-size-12 text-muted">
                  <p class="mb-1">
                    {{ item.textNotif }}
                  </p>
                  <p class="mb-0">
                    <i class="mdi mdi-clock-outline"></i>
                    {{ item.dateNotif }}
                  </p>
                </div>
              </div>
            </div>
          </a>
          <a v-if="item.seen == false" href="javascript:void(0);" class="text-succes col-md-1 mt-4" v-b-tooltip.hover
            @click="traiter(item.id)" title="Traiter">
            <i class="mdi mdi-check font-size-18"></i>
          </a>

        </div>

        <!--Fin Trajet : type 5 -->
        <div class="text-reset notification-item row rowNotif mt-3" v-if="item.typeNotif == 5">
          <span class="mt-4 col-md-1" @click="goToDetail()">
            <img src="@/assets/images/map.png" alt height="30" />
          </span>
          <a :href="'/trajets/effectues'" class="col-md-9 contenuNotif voitureMargin">
            <div class="media" style="padding: 16px">
              <div class="media-body">
                <h6 class="mt-0 mb-1">Fin Trajet</h6>
                <div class="font-size-12 text-muted">
                  <p class="mb-1">
                    {{ item.textNotif }}
                  </p>
                  <p class="mb-0">
                    <i class="mdi mdi-clock-outline"></i>
                    {{ item.dateNotif }}
                  </p>
                </div>
              </div>
            </div>
          </a>
          <a v-if="item.seen == false" href="javascript:void(0);" class="text-succes col-md-1 mt-4" v-b-tooltip.hover
            @click="traiter(item.id)" title="Traiter">
            <i class="mdi mdi-check font-size-18"></i>
          </a>
        </div>

        <!--Création d'évènement : type 6 -->
        <div class="text-reset notification-item row rowNotif mt-3" v-if="item.typeNotif == 6">
          <span class="mt-4 col-md-1">
            <img src="@/assets/images/event.png" alt height="30" />
          </span>
          <a :href="'/event/evenement'" class="col-md-9 contenuNotif voitureMargin">
            <div class="media" style="padding: 16px">
              <div class="media-body">
                <h6 class="mt-0 mb-1">Évènement</h6>
                <div class="font-size-12 text-muted">
                  <p class="mb-1">
                    {{ item.textNotif }}
                  </p>
                  <p class="mb-0">
                    <i class="mdi mdi-clock-outline"></i>
                    {{ item.dateNotif }}
                  </p>
                </div>
              </div>
            </div>
          </a>
          <a v-if="item.seen == false" href="javascript:void(0);" class="text-succes col-md-1 mt-4" v-b-tooltip.hover
            @click="traiter(item.id)" title="Traiter">
            <i class="mdi mdi-check font-size-18"></i>
          </a>
        </div>

        <!--Demande de retrait  : type 7 -->
        <div class="text-reset notification-item row rowNotif mt-3" v-if="item.typeNotif == 7">
          <span class="mt-4 col-md-1">
            <img src="@/assets/images/wdraw.png" alt height="30" />
          </span>
          <a :href="'/virements/retrait'" class="col-md-9 contenuNotif voitureMargin">
            <div class="media" style="padding: 16px">
              <div class="media-body">
                <h6 class="mt-0 mb-1">Demande de retrait</h6>
                <div class="font-size-12 text-muted">
                  <p class="mb-1">
                    {{ item.textNotif }}
                  </p>
                  <p class="mb-0">
                    <i class="mdi mdi-clock-outline"></i>
                    {{ item.dateNotif }}
                  </p>
                </div>
              </div>
            </div>
          </a>
          <a v-if="item.seen == false" href="javascript:void(0);" class="text-succes col-md-1 mt-4" v-b-tooltip.hover
            @click="traiter(item.id)" title="Traiter">
            <i class="mdi mdi-check font-size-18"></i>
          </a>
        </div>

        <!-- Mise en location : type 8 @/assets/images/car.png -->
        <div  class="text-reset notification-item row rowNotif mt-3" v-if="item.typeNotif == 8" @click="goToUser(item.idUser)">
          <span class="mt-4 col-md-1">
            <img src="@/assets/images/car.png" alt height="30" />
          </span>
          <a  class="col-md-10 contenuNotif">
            <div class="media" style="padding: 16px">
              <div class="media-body">
                <h6 class="mt-0 mb-1">VTC</h6>
                <div class="font-size-12 text-muted">
                  <p class="mb-1">
                    {{ item.textNotif }}
                  </p>
                  <p class="mb-0">
                    <i class="mdi mdi-clock-outline"></i>
                    {{ item.dateNotif }}
                  </p>
                </div>
              </div>
            </div>
          </a>
          <a v-if="item.seen == false" href="javascript:void(0);" class="text-succes col-md-1 mt-4" v-b-tooltip.hover
            @click="traiter(item.id)" title="Traiter">
            <i class="mdi mdi-check font-size-18"></i>
          </a>

        </div>


      </div>
    </div>

    <b-modal id="modal-backdrop" centered v-model="showConfirm"
      title="Voulez-vous vraiment traiter toute les notifications ?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConfirm = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="treatAllNotif">Oui</b-button>
      </div>
    </b-modal>
  </div>
</template>
<style>
.historique {
  height: auto;
}

.myCorp {
  overflow-y: scroll;

  height: 50rem;
}

/*  @media only screen and (max-width: 1376px) {
  .myRow .myspan {


width: 48px;
height: auto;


}

.card-body{
  overflow-y: scroll;

}

}  */
/* @media only screen and (max-width: 1199px) {

  .myRow .myspan {
    max-width: 65px;

width: 48px;
height: auto;


}
.myRow .myspan img{
width: 100%;
height: 100%;
object-fit: cover;
margin: auto 0;
}
.myRow .myspan img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto 0;
}
}
@media only screen and (max-width: 1888px) {
  .historique{
    height: 100%;
    overflow-y: scroll;
  }
  .myRow{
  display: flex;
  justify-content: space-between;
padding-left: 15px;
width: 100%!important;
}

.myRow .myspan {
flex: 5%;
}
.myRow .myA {
flex: 80%;
}
.myRow .myOther {
flex: 5%;
}

.myRow .myspan {
  max-width: 25%;

  width: 50%;
  height: 50%;
margin: auto;

}
.myRow .myspan img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto 0;
}
 
 
} 
.card-title {
  position: relative;
}

.badgeNotif{
  background-color: #fa3e3e;
  border-radius: 50%;
  color: white;
 width: 18px;
 height: 18px;
padding-top: 1px;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 10px;
  text-align: center;
  
 
}

.card-title ion-icon{
  font-size: 30px;
}






*/

.rowNotif:first-child {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}

.rowNotif:first-child .contenuNotif .media {
  flex: 90% !important;
}

.contenuNotif.voitureMargin {
  margin-left: -2%;
}

@media only screen and (max-width: 1460px) {
  .rowNotif:first-child {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
  }

  .contenuNotif.voitureMargin {
    margin-left: -6% !important;
  }
}

.lightButton ion-icon {
  font-size: 20px;
  color: #505d69;
  ;
}

.lightButton span {
  color: #505d69
}

.lightButton {
  background: #eff2f7;
  outline: none;
  display: flex;
  align-items: center;
  border: none;

}

.text-succes {
  color: #505d69;
}

.lightButton:hover {
  background: #3aaa35;
}

.lightButton:hover span {
  color: #fff;
}

.lightButton:hover ion-icon {
  color: #fff;
}

.lightButton span {
  color: #505d69;
  text-decoration: double;
  margin-left: 0.2rem;
}

.badge {
  padding: 4px 6px;

  color: #fff;
  font-size: 17px;
  background: #3aaa35;
}

.formG {
  width: 14rem;

}

.formG .multiselect {
  max-height: 0.8rem !important;

}
</style>
